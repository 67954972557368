<script setup>
import {computed} from 'vue';

defineOptions({
    inheritAttrs: true,
});

const emit = defineEmits(['update:checked']);

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    styled: {
        type: Boolean,
        default: true,
    },
    value: {
        type: [String, Number, Boolean],
        default: true,
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});

const checkboxCss = props.styled
    ? 'checkbox checkbox-success checkbox-sm'
    : 'rounded border-gray-300 text-info shadow-sm focus:ring-info';
</script>

<template>
    <input
        :id="name"
        v-model="proxyChecked"
        :name="name"
        type="checkbox"
        :value="value"
        :disabled="disabled"
        :class="checkboxCss" />
</template>
